import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSwiper } from 'swiper/react';

import { screen } from '@/components/common/breakpoints';
import {
  useAutoplayOverride,
  useHandleSlideChange,
} from '@/components/common/Swiper/Common';
import {
  ProgressBar,
  ProgressBarInner,
} from '@/components/common/Swiper/Custom/Pagination/ProgressBar';

import type { MutableRefObject, ReactElement } from 'react';

interface DetailedPaginationProps {
  index: number;
  component: ReactElement;
  slidesPerView: number;
  progressBarRef: MutableRefObject<HTMLDivElement>;
}

export const DetailedPagination = ({
  index,
  component, // todo: not sure on this name
  slidesPerView,
  progressBarRef,
}: DetailedPaginationProps) => {
  const swiper = useSwiper();
  const { activeIndex } = useHandleSlideChange();
  const { autoplayRunning, overrideAutoplay, paginationHandler } =
    useAutoplayOverride({
      index,
      slidesPerView,
    });

  return (
    <Container
      className={`custom-bullet ${Math.ceil(activeIndex / slidesPerView) === index ? 'active' : ''}`}
      onClick={() => paginationHandler()}
      autoplayActive={autoplayRunning}
      overrideAutoplay={overrideAutoplay}
    >
      <ProgressBar>
        {swiper.activeIndex === index && (
          <ProgressBarInner key={activeIndex} ref={progressBarRef} />
        )}
      </ProgressBar>
      {component}
    </Container>
  );
};

export const Container = styled.button<{
  autoplayActive: boolean;
  overrideAutoplay: boolean;
}>`
  position: relative;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0;

  img {
    opacity: 0.48;
  }

  &:hover {
    ${ProgressBar} {
      height: 2px;
    }
    img {
      opacity: 0.64;
    }
    p {
      color: var(--text-warm-base);
    }
    p:first-of-type {
      color: var(--text-strong);
      text-decoration: underline;
      text-underline-offset: 3px;
      text-decoration-thickness: 1px;
      text-decoration-color: var(--border-strong);
    }
  }

  &.active {
    ${ProgressBarInner} {
      background: ${({ autoplayActive }) =>
        !autoplayActive && 'var(--icon-action)'};
      width: ${({ overrideAutoplay }) => (overrideAutoplay ? '100%' : '')};
      ${({ autoplayActive }) =>
        !autoplayActive &&
        css`
          --progress: 1;
        `}
    }
    p {
      color: var(--text-strong);
    }
    img {
      opacity: 1;
    }
  }

  &:focus-visible {
    outline: none;
    img {
      outline: 2px solid var(--border-action);
      outline-offset: 2px;
      border-radius: var(--radius-component-sm);
    }
    p:first-of-type {
      outline: 2px solid var(--border-action);
      outline-offset: 2px;
      border-radius: var(--radius-component-sm);
    }
  }
`;

export const DetailedPaginationContainer = styled.div<{
  numberOfLogos: number;
}>`
  display: none;
  width: 100%;

  ${screen.md} {
    display: grid;
    gap: var(--space-block-md);

    grid-template-columns: ${({ numberOfLogos }) =>
      `repeat(${numberOfLogos}, 1fr)`};
  }
`;
export { ProgressBarInner, ProgressBar };
