import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { DecorativeCard } from '@/components/common/DecorativeCard';
import { Image } from '@/components/common/image';
import { Paragraph } from '@/components/common/MarkUp';
import { Stack } from '@/components/layout/Stack';
import { getButtonComponentThemeSolid, getTextColour } from '@/lib/colourUtils';
import { blockUrlWithAudience } from '@/lib/utils';

import type { SurfaceColourType } from '@/types/colours';
import type { SanityAudienceType } from '@/types/sanity';
import type {
  BlockWithAudience,
  HotspotImage,
  TrackingTagType,
} from '@/types/shared';

export interface QuoteCardProps extends BlockWithAudience {
  backgroundColour: SurfaceColourType;
  decoration: {
    enable?: boolean;
    variant?: 'vertical' | 'horizontal' | 'diagonal';
    decorationColourToken?: string;
  };
  company: string;
  testimonial: {
    quote: string;
    author?: string;
    role?: string;
    authorImage?: HotspotImage;
  };

  ctaButton?: {
    _type: 'ctaButton';
    buttonText?: string;
    audienceRef?: SanityAudienceType;
    noAudienceToggle?: boolean;
    buttonLink?: string;
    trackingTag?: TrackingTagType;
    buttonAccessibleLabel?: string;
  };
}

export const QuoteCard = ({
  backgroundColour,
  company,
  testimonial,
  decoration,
  ctaButton,
  pageAudience,
  isAudienceSwitcherEnabled,
}: QuoteCardProps) => {
  const backgroundColourToken = backgroundColour?.token;

  return (
    <DecorativeCard backgroundColour={backgroundColour} decoration={decoration}>
      <Stack spacing="--space-component-xl">
        <QuoteContainer>
          <Quote
            size="subheading-md"
            colour={getTextColour(backgroundColourToken, {
              variation: 'strong',
            })}
            iconColour={decoration?.decorationColourToken}
          >
            {testimonial.quote}
          </Quote>
        </QuoteContainer>
        <BottomCard>
          <Author>
            {testimonial?.authorImage?.asset && (
              <AuthorImage objectFit="cover" {...testimonial.authorImage} />
            )}
            <AuthorDetails spacing="--space-component-sm">
              <Paragraph
                size="label-md"
                colour={getTextColour(backgroundColourToken, {
                  variation: 'strong',
                })}
              >
                {testimonial.author}
              </Paragraph>
              <Paragraph
                size="body-md"
                colour={getTextColour(backgroundColourToken, {
                  variation: 'base',
                })}
              >
                {testimonial?.role && `${testimonial.role} at`} {company}
              </Paragraph>
            </AuthorDetails>
          </Author>
          {ctaButton?.buttonLink && (
            <CtaButton
              href={blockUrlWithAudience(
                ctaButton.buttonLink,
                ctaButton.audienceRef,
                pageAudience,
                ctaButton.noAudienceToggle,
                isAudienceSwitcherEnabled,
              )}
              variant={getButtonComponentThemeSolid(backgroundColourToken)}
              iconPosition="right"
              aria-label={ctaButton.buttonAccessibleLabel}
              className={
                ctaButton.trackingTag?.blockName &&
                `tracking-${ctaButton.trackingTag.blockName}__${ctaButton.trackingTag.blockAction}`
              }
              size="lg"
              icon="ArrowRight"
            >
              {ctaButton.buttonText}
            </CtaButton>
          )}
        </BottomCard>
      </Stack>
    </DecorativeCard>
  );
};

const Quote = styled(Paragraph)<{
  iconColour: string;
}>`
  font-weight: var(--font-weight-medium);
  padding-right: 0;
  position: relative;
  padding-top: calc(32px + var(--space-component-xl));

  :before {
    content: '';
    mask: url(/images/decorative/quote-icon.svg);
    mask-size: cover;
    height: 32px;
    width: 32px;
    position: absolute;
    display: inline-block;
    top: 0px;
    background: ${({ iconColour }) =>
      iconColour ? `var(${iconColour})` : 'var(--icon-sunbeam)'};
  }

  ${screen.sm} {
    padding-right: 120px;
  }
  ${screen.lg} {
    padding-right: 192px;
  }
`;
const QuoteContainer = styled.div`
  flex: 1;
  height: 100%;
  align-content: center;
`;

const Author = styled.div`
  display: flex;
  gap: var(--space-component-md);
  width: 100%;
`;

const AuthorImage = styled(Image)`
  height: 48px;
  width: 48px;
  border-radius: 50%;
`;

const AuthorDetails = styled(Stack)``;

const CtaButton = styled(Button)`
  position: relative;
  width: 100%;
  justify-content: center;

  // this is the button icon, we dont want it visible for mobile
  > div {
    display: none;
  }

  ${screen.sm} {
    width: max-content;

    > div {
      display: block;
    }
  }
  ${screen.md} {
    margin-top: 0;
  }
`;

const BottomCard = styled(Stack)`
  display: flex;
  flex-direction: column;

  ${screen.sm} {
    flex-direction: row;
  }
`;
