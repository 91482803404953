import dynamic from 'next/dynamic';

import { ShowcaseCarousel } from '@/components/blocks/ShowcaseCarousel/ShowcaseCarousel';
import { SliderCarousel } from '@/components/blocks/SliderCarousel/SliderCarousel';
import { AbTestWrapper } from '@/lib/AbTesting';
import { AbBlocksContainer } from '@/lib/AbTesting/AbBlocksContainer';

import type { LatestPostsDataProps } from '@/components/blocks/LatestPosts';
import type { ProgrammeCatalogueProps } from '@/components/blocks/ProgrammeCatalogue';
import type { ProgrammeGridProps } from '@/components/blocks/ProgrammeGrid';
import type { SanityAudienceType, SanityBlock } from '@/types/sanity';

const PrimaryProductHero = dynamic(() =>
  import('@/components/blocks/PrimaryProductHero').then(
    (mod) => mod.PrimaryProductHero,
  ),
);
const QuoteModule = dynamic(() =>
  import('@/components/blocks/QuoteModule').then((mod) => mod.QuoteModule),
);
const Timeline = dynamic(() =>
  import('./Timeline').then((mod) => mod.Timeline),
);
const ThreeCardColumns = dynamic(() =>
  import('./ThreeCardColumns').then((mod) => mod.ThreeCardColumns),
);
const ImageTextColumns = dynamic(() =>
  import('./ImageTextColumns').then((mod) => mod.ImageTextColumns),
);
const CollageText = dynamic(() =>
  import('./CollageText').then((mod) => mod.CollageText),
);
const CompanyTestimonials = dynamic(() =>
  import('./CompanyTestimonials').then((mod) => mod.CompanyTestimonials),
);
const Comparison = dynamic(() =>
  import('./Comparison').then((mod) => mod.Comparison),
);
const Contact = dynamic(() => import('./Contact').then((mod) => mod.Contact));
const CtaCard = dynamic(() =>
  import('./CtaCard').then((mod) => mod.CtaCardBlock),
);
const Divider = dynamic(() => import('./Divider').then((mod) => mod.Divider));
const Faq = dynamic(() => import('./Faq').then((mod) => mod.Faq));
const FeatureExplainer = dynamic(() =>
  import('./FeatureExplainer').then((mod) => mod.FeatureExplainer),
);
const TwoColumnHeadingText = dynamic(() =>
  import('./TwoColumnHeadingText').then((mod) => mod.TwoColumnHeadingText),
);

const LongFormContent = dynamic(() =>
  import('./LongFormContent').then((mod) => mod.LongFormContent),
);
const IllustratedHero = dynamic(() =>
  import('./IllustratedHero').then((mod) => mod.IllustratedHero),
);
const CreativeHero = dynamic(() =>
  import('./heroes/CreativeHero').then((mod) => mod.CreativeHero),
);
const PolicyHero = dynamic(() =>
  import('./PolicyHero').then((mod) => mod.PolicyHero),
);
const InformationText = dynamic(() =>
  import('./InformationText').then((mod) => mod.InformationText),
);

const PolicyList = dynamic(() =>
  import('./PolicyList').then((mod) => mod.PolicyList),
);
const MultipleTestimonials = dynamic(() =>
  import('./MultipleTestimonials').then((mod) => mod.MultipleTestimonials),
);
const MultipointColumnExplainer = dynamic(() =>
  import('./MultipointColumnExplainer').then(
    (mod) => mod.MultipointColumnExplainer,
  ),
);
const Checklist = dynamic(() =>
  import('./Checklist').then((mod) => mod.Checklist),
);
const PopoutVideo = dynamic(() =>
  import('./PopoutVideo').then((mod) => mod.PopoutVideo),
);
const Press = dynamic(() => import('./Press').then((mod) => mod.Press));
const ProgrammeCarousel = dynamic(() =>
  import('./ProgrammeCarousel').then((mod) => mod.ProgrammeCarousel),
);
const ProgrammeGrid = dynamic(() =>
  import('./ProgrammeGrid').then((mod) => mod.ProgrammeGrid),
);
const ProgrammeSpecifications = dynamic(() =>
  import('./ProgrammeSpecifications').then(
    (mod) => mod.ProgrammeSpecifications,
  ),
);
const Stats = dynamic(() => import('./Stats').then((mod) => mod.Stats));
const Triage = dynamic(() => import('./Triage').then((mod) => mod.Triage));
const Usp = dynamic(() => import('./Usp').then((mod) => mod.Usp));
const Values = dynamic(() => import('./Values').then((mod) => mod.Values));
const ImageBulletPointsDescription = dynamic(() =>
  import('./ImageBulletPointsDescription').then(
    (mod) => mod.ImageBulletPointsDescription,
  ),
);
const Carousel = dynamic(() =>
  import('./Carousel').then((mod) => mod.Carousel),
);
const ProgrammeCatalogue = dynamic(() =>
  import('./ProgrammeCatalogue').then((mod) => mod.ProgrammeCatalogue),
);
const StackedCards = dynamic(() =>
  import('./StackedCards').then((mod) => mod.StackedCards),
);
const HubspotFormBlock = dynamic(() =>
  import('./hubspotFormBlock').then((mod) => mod.HubspotFormBlock),
);
const VevEmbed = dynamic(() =>
  import('./VevEmbed').then((mod) => mod.VevEmbed),
);
const PartnerLogos = dynamic(() =>
  import('./PartnerLogos').then((mod) => mod.PartnerLogos),
);
const TestimonialsCarousel = dynamic(() =>
  import('./TestimonialsCarousel').then((mod) => mod.TestimonialsCarousel),
);

const EmployersCta = dynamic(() =>
  import('./EmployersCta').then((mod) => mod.EmployersCta),
);
const ThreeColumn = dynamic(() =>
  import('./ThreeColumn').then((mod) => mod.ThreeColumn),
);
const MultiColumn = dynamic(() =>
  import('./MultiColumn').then((mod) => mod.MultiColumn),
);
const AccordionWithImage = dynamic(() =>
  import('./AccordionWithImage').then((mod) => mod.AccordionWithImage),
);
const EmployersHero = dynamic(() =>
  import('../legacy/blocks/heroes/EmployersHero').then(
    (mod) => mod.EmployersHero,
  ),
);
const CtaBanner = dynamic(() =>
  import('./CtaBanner').then((mod) => mod.CtaBanner),
);

const CtaButton = dynamic(() =>
  import('./CtaButton').then((mod) => mod.CtaButton),
);
const LatestPosts = dynamic(() =>
  import('./LatestPosts').then((mod) => mod.LatestPosts),
);
const DualCtaCards = dynamic(() =>
  import('./DualCtaCards').then((mod) => mod.DualCtaCards),
);
const SuccessStoryCarousel = dynamic(() =>
  import('./SuccessStoryCarousel').then((mod) => mod.SuccessStoryCarousel),
);
const HeroTextLeftImageRight = dynamic(() =>
  import('../legacy/blocks/heroes/HeroTextLeftImageRight').then(
    (mod) => mod.HeroTextLeftImageRight,
  ),
);
const DelineatedCtaCards = dynamic(() =>
  import('./DelineatedCtaCards').then((mod) => mod.DelineatedCtaCards),
);
const TooltipButton = dynamic(() =>
  import('./TooltipButton').then((mod) => mod.TooltipButton),
);
const FeatureShowoffGrid = dynamic(() =>
  import('./FeatureShowoffGrid').then((mod) => mod.FeatureShowoffGrid),
);
const MasonryGrid = dynamic(() =>
  import('./MasonryGrid').then((mod) => mod.MasonryGrid),
);
const MultiColumnLayout = dynamic(() =>
  import('./MultiColumnLayout').then((mod) => mod.MultiColumnLayout),
);
const ComparisonExtended = dynamic(() =>
  import('./ComparisonExtended').then((mod) => mod.ComparisonExtended),
);
const ProductHighlight = dynamic(() =>
  import('./TwoColumnLayout/ProductHighlight').then(
    (mod) => mod.ProductHighlight,
  ),
);
const CardCarousel = dynamic(() =>
  import('./CardCarousel').then((mod) => mod.CardCarousel),
);
const FeaturedGrid = dynamic(() =>
  import('./FeaturedGrid').then((mod) => mod.FeaturedGrid),
);
const ArticleGrid = dynamic(() =>
  import('./ArticleGrid').then((mod) => mod.ArticleGrid),
);
const EmailSignUpForm = dynamic(() =>
  import('./EmailSignUpForm').then((mod) => mod.EmailSignUpForm),
);
const StandaloneHeading = dynamic(() =>
  import('./StandaloneTitle').then((mod) => mod.StandaloneTitle),
);
const StickySubnav = dynamic(() =>
  import('./StickySubnav').then((mod) => mod.StickySubnav),
);
const StickySubnavAnchor = dynamic(() =>
  import('./StickySubnav').then((mod) => mod.StickySubnavAnchor),
);

const USPWithMedia = dynamic(() =>
  import('./USPWithMedia').then((mod) => mod.USPWithMedia),
);
const SplitImageHero = dynamic(() =>
  import('./heroes/SplitImage').then((mod) => mod.SplitImage),
);
const FullBackgroundImageHero = dynamic(() =>
  import('./heroes/FullBackgroundImage').then((mod) => mod.FullBackgroundImage),
);

export interface ExtraProps {
  programmeGrid?: ProgrammeGridProps['programmes'];
  programmesCatalogue?: ProgrammeCatalogueProps['programmes'];
  latestPostsData?: LatestPostsDataProps;
  pageAudience?: SanityAudienceType;
  isAudienceSwitcherEnabled?: boolean;
}

export function renderBlocks(blocks, extraProps: ExtraProps = {}) {
  if (!blocks) {
    return [];
  }

  return blocks.map((block) => {
    if (block._type === 'abTestContainer') {
      return (
        <AbTestWrapper
          key={block._key}
          abTest={block.abTest}
          component={AbBlocksContainer}
          extraProps={extraProps}
        />
      );
    }

    return renderSanityBlock(block, extraProps, blocks);
  });
}

export function renderSanityBlock(
  block,
  extraProps?: ExtraProps,
  blocks?: SanityBlock[],
) {
  if (block._type === 'blockCollection') {
    return renderBlocks(block.blocks);
  }

  switch (block._type) {
    case 'accordionWithImage':
      return (
        <AccordionWithImage
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'articleGrid':
      return (
        <ArticleGrid
          key={block._key}
          {...block}
          latestPostsData={extraProps.latestPostsData}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'cardCarousel':
      return (
        <CardCarousel
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'carousel':
      return (
        <Carousel
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );

    case 'checklist':
      return (
        <Checklist
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'collageText':
      return (
        <CollageText
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'companyTestimonials':
      return (
        <CompanyTestimonials
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'comparison':
      return <Comparison key={block._key} {...block} />;
    case 'comparisonExtended':
      return <ComparisonExtended key={block._key} {...block} />;
    case 'contact':
      return (
        <Contact
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'ctaCard':
      return (
        <CtaCard
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'delineatedCtaCards':
      return (
        <DelineatedCtaCards
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'divider':
      return <Divider key={block._key} {...block} />;
    case 'dualCtaCards':
      return (
        <DualCtaCards
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'employersBanner':
    case 'ctaBanner':
      return (
        <CtaBanner
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'ctaButton':
      return (
        <CtaButton
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'emailSignUpForm':
      return <EmailSignUpForm key={block._key} {...block} />;
    case 'employersCta':
      return (
        <EmployersCta
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'faq':
      return (
        <Faq
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'featuredGrid':
      return (
        <FeaturedGrid
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'featureExplainer':
      return (
        <FeatureExplainer
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'featureShowoffGrid':
      return (
        <FeatureShowoffGrid
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'standaloneHeading':
      return <StandaloneHeading key={block._key} {...block} />;
    case 'hubspotFormBlock':
      return (
        <HubspotFormBlock
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'illustratedHero':
      return (
        <IllustratedHero
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'creativeHero':
      return (
        <CreativeHero
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'imageBulletPointsDescription':
      return (
        <ImageBulletPointsDescription
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'imageTextColumns':
      return <ImageTextColumns key={block._key} {...block} />;
    case 'informationText':
      return <InformationText key={block._key} {...block} />;
    case 'latestPosts':
      return (
        <LatestPosts
          key={block._key}
          {...block}
          latestPostsData={extraProps.latestPostsData}
        />
      );
    case 'longFormContent':
      return (
        <LongFormContent
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'multiColumn':
      return (
        <MultiColumn
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'masonryGrid':
      return <MasonryGrid key={block._key} {...block} />;
    case 'multiColumnLayout':
      return (
        <MultiColumnLayout
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'multipleTestimonials':
      return <MultipleTestimonials key={block._key} {...block} />;
    case 'multipointColumnExplainer':
      return (
        <MultipointColumnExplainer
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'partnerLogos':
      return <PartnerLogos key={block._key} {...block} />;
    case 'policyHero':
      return <PolicyHero key={block._key} {...block} />;
    case 'policyList':
      return <PolicyList key={block._key} {...block} />;
    case 'popoutVideo':
      return <PopoutVideo key={block._key} {...block} />;
    case 'press':
      return (
        <Press
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'productHighlight':
      return (
        <ProductHighlight
          key={block.key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'programmeCarousel':
      return (
        <ProgrammeCarousel
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'programmeCatalogue':
      return (
        <ProgrammeCatalogue
          key={block._key}
          {...block}
          programmes={extraProps.programmesCatalogue}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );

    case 'programmeSpecifications':
      return <ProgrammeSpecifications key={block._key} {...block} />;
    case 'quoteModule':
      return <QuoteModule key={block._key} {...block} />;
    case 'showcaseCarousel':
      return (
        <ShowcaseCarousel
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'sliderCarousel':
      return (
        <SliderCarousel
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'stackedCards':
      return (
        <StackedCards
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'usHomepageHero':
      return <PrimaryProductHero key={block._key} {...block} />;
    case 'stats':
      return <Stats key={block._key} {...block} />;
    case 'stickySubnav':
      return <StickySubnav key={block._key} {...block} allBlocks={blocks} />;
    case 'stickySubnavAnchor':
      return <StickySubnavAnchor key={block._key} {...block} />;
    case 'successStoryCarousel':
      return (
        <SuccessStoryCarousel
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'testimonialsCarousel':
      return (
        <TestimonialsCarousel
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'threeCardColumns':
      return (
        <ThreeCardColumns
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'threeColumn':
      return <ThreeColumn key={block._key} {...block} />;
    case 'timeline':
      return (
        <Timeline
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'tooltipButton':
      return <TooltipButton key={block._key} {...block} />;
    case 'triage':
      return (
        <Triage
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'twoColumnHeadingText':
      return (
        <TwoColumnHeadingText
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'usp':
      return (
        <Usp
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'uspWithMedia':
      return (
        <USPWithMedia
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'values':
      return (
        <Values
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'vevEmbed':
      return <VevEmbed key={block._key} {...block} />;

    // Hero Blocks
    case 'splitImageHero':
      return (
        <SplitImageHero
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );
    case 'fullBackgroundImageHero':
      return (
        <FullBackgroundImageHero
          key={block._key}
          {...block}
          pageAudience={extraProps.pageAudience}
          isAudienceSwitcherEnabled={extraProps.isAudienceSwitcherEnabled}
        />
      );

    // Legacy Blocks
    case 'employersHero':
      return <EmployersHero key={block._key} {...block} />;

    case 'heroTextLeftImageRight':
      return <HeroTextLeftImageRight key={block._key} {...block} />;

    case 'programmeGrid':
      return (
        <ProgrammeGrid
          key={block._key}
          {...block}
          programmes={extraProps.programmeGrid}
        />
      );

    // Deprecated blocks
    case 'sketchyPanel':
      return null;
    // Deprecated blocks end
    default:
      // eslint-disable-next-line no-case-declarations
      const error = `Block type "${block._type}" not supported`;
      console.error(error);
      return <span data-error={error} aria-hidden />;
  }
}
