import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSwiper } from 'swiper/react';

import {
  useAutoplayOverride,
  useHandleSlideChange,
} from '@/components/common/Swiper/Common';

import { ProgressBarInner } from './DetailedPagination';

import type { MutableRefObject } from 'react';

interface SimplePaginationProps {
  index: number;
  slidesPerView: number;
  progressBarRef: MutableRefObject<HTMLDivElement>;
}

export const SimplePagination = ({
  index,
  slidesPerView,
  progressBarRef,
}: SimplePaginationProps) => {
  const swiper = useSwiper();
  const { activeIndex } = useHandleSlideChange();
  const { autoplayRunning, overrideAutoplay } = useAutoplayOverride({
    index,
    slidesPerView,
  });
  return (
    <Container
      autoplayActive={autoplayRunning}
      overrideAutoplay={overrideAutoplay}
    >
      <Indicator
        key={index}
        className={`custom-bullet ${Math.ceil(activeIndex / slidesPerView) === index ? 'active' : ''}`}
        autoplayActive={autoplayRunning}
        overrideAutoplay={overrideAutoplay}
      >
        {swiper.activeIndex === index && (
          <ProgressBarInner key={activeIndex} ref={progressBarRef} />
        )}
      </Indicator>
    </Container>
  );
};

export const Container = styled.div<{
  autoplayActive: boolean;
  overrideAutoplay: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Indicator = styled.div<{
  autoplayActive: boolean;
  overrideAutoplay: boolean;
}>`
  height: 1px;
  width: 14px;
  background: var(--border-warm-base);
  border-radius: var(--radius-full);

  &.active {
    width: 34px;

    > div {
      width: ${({ overrideAutoplay }) => (overrideAutoplay ? '100%' : '')};

      ${({ autoplayActive }) => {
        if (autoplayActive) {
          return css`
            background-color: var(--icon-action);
          `;
        } else {
          return css`
            --progress: 1;
          `;
        }
      }}
    }
  }
`;

export const SimplePaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 20px 0;
`;
