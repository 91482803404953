import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSwiper } from 'swiper/react';

import { screen } from '@/components/common/breakpoints';
import {
  useAutoplayOverride,
  useHandleSlideChange,
} from '@/components/common/Swiper/Common';
import {
  Container,
  ProgressBarInner,
} from '@/components/common/Swiper/Custom/Pagination/DetailedPagination';

import type { MutableRefObject } from 'react';

interface MobileDetailedPaginationProps {
  index: number;
  slidesPerView: number;
  progressBarRef: MutableRefObject<HTMLDivElement>;
}

export const MobileDetailedPagination = ({
  index,
  slidesPerView,
  progressBarRef,
}: MobileDetailedPaginationProps) => {
  const swiper = useSwiper();
  const { activeIndex } = useHandleSlideChange();
  const { autoplayRunning, overrideAutoplay } = useAutoplayOverride({
    index,
    slidesPerView,
  });
  return (
    <Container
      autoplayActive={autoplayRunning}
      overrideAutoplay={overrideAutoplay}
    >
      <Indicator
        key={index}
        className={`custom-bullet ${Math.ceil(activeIndex / slidesPerView) === index ? 'active' : ''}`}
        autoplayActive={autoplayRunning}
        overrideAutoplay={overrideAutoplay}
      >
        {swiper.activeIndex === index && (
          <ProgressBarInner key={activeIndex} ref={progressBarRef} />
        )}
      </Indicator>
    </Container>
  );
};

export const MobileDetailedPaginationTab = ({
  components,
}: {
  components: React.ReactNode[];
}) => {
  const { activeIndex } = useHandleSlideChange();

  return components[activeIndex];
};

const Indicator = styled.div<{
  autoplayActive: boolean;
  overrideAutoplay: boolean;
}>`
  height: 2px;
  width: 14px;
  background: var(--border-warm-base);
  border-radius: var(--radius-full);

  &.active {
    width: 34px;
    > div {
      background: ${({ autoplayActive }) =>
        !autoplayActive && 'var(--icon-action)'};

      width: ${({ overrideAutoplay }) => (overrideAutoplay ? '100%' : '')};
      ${({ autoplayActive }) =>
        !autoplayActive &&
        css`
          --progress: 1;
        `}
    }
  }
`;

export const MobileDetailedPaginationContainer = styled.div`
  p {
    color: var(--text-strong);
  }
  ${screen.md} {
    display: none;
  }
`;

export const MobileIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 20px 0;
`;
